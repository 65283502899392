import React, { useEffect, useRef } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import useIsMount from '@/util/hook/useIsMount.ts';

interface IProps {
    className?: string;
    latitude?: number;
    longitude?: number;
}

const DefaultMap: React.FunctionComponent<IProps> = ({ className, latitude, longitude }) => {
    const { isMount } = useIsMount();
    const mapRef = useRef<google.maps.Map | null>(null);

    const center = {
        lat: latitude ?? 37.5759,  // 기본값으로 서울 광화문 좌표 설정
        lng: longitude ?? 126.9768
    };

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.panTo(center);
        }
    }, [latitude, longitude]);

    const mapStyles = { width: '100%', height: '100%' };

    const mapOptions = {
        zoom: 15,
        mapId: '8d4d2c95ae9b2bdf', // 여기에서 mapId 설정
        // disableDefaultUI: true,  // 기본 UI 비활성화
        zoomControl: false,      // 줌 컨트롤 비활성화
        // mapTypeControl: false,   // 지도 유형 선택 비활성화
        streetViewControl: false, // 스트리트 뷰 컨트롤 비활성화
        fullscreenControl: false // 풀스크린 컨트롤 비활성화
    };

    return (
        <div className={`${className}`} onClick={e => e.stopPropagation()}>
            {isMount && latitude && longitude && (
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    center={center}
                    zoom={mapOptions.zoom}
                    options={mapOptions}  // options를 추가하여 mapId 적용
                    onLoad={(map) => {
                        mapRef.current = map;
                    }}
                >
                    <Marker
                        position={center}
                    />
                </GoogleMap>
            )}
        </div>
    );
};

export default DefaultMap;