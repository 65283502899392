import './reset.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from '@/pages/dashboard/Dashboard.tsx';
import Users from '@/pages/users/Users.tsx';
import Login from '@/pages/auth/Login.tsx';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from '@/component/util/PrivateRoute.tsx';
import Account from '@/pages/account/Account.tsx';
import { API_ENDPOINT, GOOGLE_MAPS_API_KEY } from '@/constant/constant.ts';
import HomeRoute from '@/component/util/HomeRoute.tsx';
import Home from '@/pages/landing/Home/Home.tsx';
import GuidePage from '@/pages/landing/Guide/GuidePage.tsx';
import { Suspense, useEffect } from 'react';
import { KEY_ACCESS_TOKEN, useLoginStore } from '@/store/LoginStore.ts';
import { getCookie } from '@/util/cookie.ts';
import { equalString, isEmpty } from '@/util/strings.ts';
import UserDetailMapPage from '@/pages/users/detail/UserDetailMapPage.tsx';
import DevPage from '@/pages/etc/DevPage.tsx';
import NotificationPage from '@/pages/notification/NotificationPage.tsx';
import Loader from '@/pages/etc/Loader/Loader.tsx';
import { datadogLogs } from '@datadog/browser-logs';
import { useTranslation } from 'react-i18next';
import { LoadScript } from '@react-google-maps/api';

if (equalString(import.meta.env.MODE, 'production') && API_ENDPOINT?.startsWith('https://api.clomental.com/v1')) {
    datadogLogs.init({
        clientToken: 'pub4655ddbdc7b2012ac234117ac3c70e29',
        site: 'ap1.datadoghq.com',
        service: 'Clomental-web',
        version: '0.1',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
    });
}

function App() {
    const { t } = useTranslation();
    const queryClient = new QueryClient();
    const { accessToken, logout } = useLoginStore();
    const wrapPrivateRoute = (element: any, href = '/login') => (
        <PrivateRoute href={href}>{element}</PrivateRoute>
    );

    useEffect(() => {
        return () => {
            queryClient.removeQueries();
        };
    }, []);

    useEffect(() => {
        const cookieAccessToken = getCookie(KEY_ACCESS_TOKEN);
        if (isEmpty(cookieAccessToken)) {
            logout();
        }
    }, [accessToken]);

    useEffect(() => {
        document.title = t('app.title');
    }, [t]);

    return (
        <>
            <Suspense>
                <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                    <div className="font-sans">
                        <ToastContainer
                            position="bottom-right"
                            autoClose={3000}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable
                            pauseOnHover
                            theme="light"
                            stacked
                        />
                        <QueryClientProvider client={queryClient}>
                            <Loader />
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/" element={<HomeRoute />} />
                                    <Route path="/home" element={<Home />} />
                                    <Route path="/guide" element={<GuidePage />} />
                                    <Route path="/dashboard" element={wrapPrivateRoute(<Dashboard />)} />
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/user" element={wrapPrivateRoute(<Users />)} />
                                    <Route path="/user/:id/map" element={wrapPrivateRoute(<UserDetailMapPage />)} />
                                    <Route path="/notification" element={wrapPrivateRoute(<NotificationPage />)} />
                                    <Route path="/account" element={wrapPrivateRoute(<Account />)} />
                                    <Route path="/dev" element={wrapPrivateRoute(<DevPage />)} />
                                </Routes>
                            </BrowserRouter>
                        </QueryClientProvider>
                    </div>
                </LoadScript>
            </Suspense>
        </>
    );
}

export default App;