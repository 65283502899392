import React, { useEffect, useMemo, useState } from 'react';
import { GoogleMap, MarkerF, InfoWindowF, CircleF } from '@react-google-maps/api';
import useQueryGetUserDetail from '@/api/auth/getUser.ts';
import useIsMount from '@/util/hook/useIsMount.ts';
import { IMarker, IMarkerInfo, MarketTYPE } from '@/pages/dashboard/DashboardMapDefault.tsx';
import ReactDOMServer from 'react-dom/server';
import MarkerWindow from '@/pages/dashboard/MarkerWindow.tsx';
import { AlertStatus } from '@/api/user/getUsers.ts';

interface IProps {
    className?: string;
    userID?: number;
    defaultOpenInfo?: boolean;
}

const UserDetailMap: React.FunctionComponent<IProps> = ({ className, userID = 0, defaultOpenInfo = false }) => {
    const { data } = useQueryGetUserDetail(userID);
    const { isMount } = useIsMount();
    const [userInfo, setUserInfo] = useState<any>(null);

    const openUserInfo = (info?: IMarker) => {
        if (!info || userInfo?.id === info?.id) {
            setUserInfo(undefined);
        } else {
            const { latitude = 0, longitude = 0 } = info?.location || {};

            if (latitude === 0 && longitude === 0) {
                const infoWithoutAddress: IMarkerInfo = {
                    ...info!,
                    address: 'Coordinates not available',
                };

                infoWithoutAddress.render = ReactDOMServer.renderToString(
                    <div style={{ fontFamily: "'Noto Sans KR', sans-serif", fontWeight: 400 }}>
                        <MarkerWindow info={infoWithoutAddress} />
                    </div>
                );
                setUserInfo(infoWithoutAddress);
                return;
            }

            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
                { location: { lat: latitude, lng: longitude } },
                (results, status) => {
                    if (status === 'OK' && results && results[0]) {
                        const infoWithAddress: IMarkerInfo = {
                            ...info!,
                            address: results[0].formatted_address,
                        };

                        infoWithAddress.render = ReactDOMServer.renderToString(
                            <div style={{ fontFamily: "'Noto Sans KR', sans-serif", fontWeight: 400 }}>
                                <MarkerWindow info={infoWithAddress} />
                            </div>
                        );
                        setUserInfo(infoWithAddress);
                    } else {
                        const infoWithoutAddress: IMarkerInfo = {
                            ...info!,
                            address: 'Geocoding failed',
                        };

                        infoWithoutAddress.render = ReactDOMServer.renderToString(
                            <div style={{ fontFamily: "'Noto Sans KR', sans-serif", fontWeight: 400 }}>
                                <MarkerWindow info={infoWithoutAddress} />
                            </div>
                        );
                        setUserInfo(infoWithoutAddress);
                    }
                }
            );
        }
    };

    const getMarkerColor = (alertStatus: AlertStatus | undefined) => {
        let color = '#2966F4';
        switch (alertStatus) {
            case AlertStatus.EMERGENCY:
                color = '#FF003D';
                break;
            case AlertStatus.BIO:
                color = '#FF7D33';
                break;
            default:
                color = '#2966F4';
                break;
        }
        return color;
    };

    const marker = useMemo(() => {
        if (!data) return undefined;
        return {
            id: userID,
            deviceStatus: data?.device?.status,
            location: {
                latitude: data?.location?.latitude,
                longitude: data?.location?.longitude,
                accuracy: data?.location?.accuracy,
                timestamp: data?.location?.timestamp,
            },
            type: MarketTYPE.SINGLE,
            alertStatus: data?.alertStatus as AlertStatus ?? AlertStatus.NONE,
            users: [data],
        };
    }, [data]);

    useEffect(() => {
        if (defaultOpenInfo && data?.location) {
            openUserInfo(marker);
        }
    }, [defaultOpenInfo, data]);

    const mapOptions = {
        // disableDefaultUI: true,  // 기본 UI 비활성화
        zoomControl: false,         // 줌 컨트롤 비활성화
        // mapTypeControl: false,   // 지도 유형 선택 비활성화
        streetViewControl: false,   // 스트리트 뷰 컨트롤 비활성화
        fullscreenControl: false    // 풀스크린 컨트롤 비활성화
    };

    return (
        <>
            <div className={`${className}`}>
                {isMount && data?.device ? (
                    <div
                        key={userID}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <GoogleMap
                            center={{ lat: data?.location?.latitude || 0, lng: data?.location?.longitude || 0 }}
                            zoom={15}
                            mapContainerStyle={{ width: '100%', height: '100%' }}
                            options={mapOptions}
                        >
                            <CircleF
                                center={{ lat: data?.location?.latitude || 0, lng: data?.location?.longitude || 0 }}
                                radius={data?.location?.accuracy}
                                options={{
                                    fillColor: '#78bfffcc',
                                    strokeColor: '#78bfffcc',
                                    strokeWeight: 1,
                                }}
                            />
                            <MarkerF
                                position={{ lat: data?.location?.latitude || 0, lng: data?.location?.longitude || 0 }}
                                icon={{
                                    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
                                        `
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                            <circle cx="25" cy="25" r="20" fill="${getMarkerColor(data?.alertStatus)}" stroke="white" stroke-width="3"/>
                                        </svg>
                                        `
                                    )}`,
                                    scaledSize: new google.maps.Size(40, 40),
                                }}
                                onClick={() => openUserInfo(marker)}
                            />
                            {userInfo && (
                                <InfoWindowF
                                    position={{ lat: userInfo?.location?.latitude || 0, lng: userInfo?.location?.longitude || 0 }}
                                    onCloseClick={() => setUserInfo(undefined)}
                                >
                                    <div
                                        style={{ fontFamily: "'Noto Sans KR', sans-serif", fontWeight: 400 }}
                                        dangerouslySetInnerHTML={{ __html: userInfo?.render }}
                                    />
                                </InfoWindowF>
                            )}
                        </GoogleMap>
                    </div>
                ) : (
                    <div className={'bg-gray1 h-full text-body text-center content-center'}>
                        기기를 연결해주세요
                    </div>
                )}
            </div>
        </>
    );
};

export default UserDetailMap;
