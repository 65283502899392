import React, { Suspense, useEffect, useMemo, useState } from 'react';
import PageConfig from '@/component/layout/PageConfig.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import CloseButton from '@/component/button/CloseButton.tsx';
import { GoogleMap, MarkerF, InfoWindowF, CircleF } from '@react-google-maps/api';
import useQueryGetUserDetail from '@/api/auth/getUser.ts';
import { IMarker, IMarkerInfo, MarketTYPE } from '@/pages/dashboard/DashboardMapDefault.tsx';
import { AlertStatus } from '@/api/user/getUsers.ts';
import useIsMount from '@/util/hook/useIsMount.ts';
import ReactDOMServer from 'react-dom/server';
import MarkerWindow from '@/pages/dashboard/MarkerWindow.tsx';
import HomeIcon from '@/assets/ic_map_house.svg?react';

export interface IProps {}

const UserDetailMapPage: React.FC<IProps> = ({}) => {
    const params = useParams();
    const userID = Number(params?.id);
    const navigate = useNavigate();
    const { data } = useQueryGetUserDetail(userID);
    const [userInfo, setUserInfo] = useState<any>(null);
    const { isMount } = useIsMount();

    const openUserInfo = (info?: IMarker) => {
        if (!info || userInfo?.id === info?.id) {
            setUserInfo(undefined);
        } else {
            const { latitude = 0, longitude = 0 } = info?.location || {};

            if (latitude === 0 && longitude === 0) {
                const infoWithoutAddress: IMarkerInfo = {
                    ...info!,
                    // locationName: 'No location',
                    address: 'Coordinates not available',
                };

                infoWithoutAddress.render = ReactDOMServer.renderToString(<MarkerWindow info={infoWithoutAddress} />);
                setUserInfo(infoWithoutAddress);
                return;
            }

            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
                { location: { lat: latitude, lng: longitude } },
                (results, status) => {
                    if (status === 'OK' && results && results[0]) {
                        const infoWithAddress: IMarkerInfo = {
                            ...info!,
                            // locationName: results[1]?.formatted_address || '',
                            address: results[0].formatted_address,
                        };

                        infoWithAddress.render = ReactDOMServer.renderToString(<MarkerWindow info={infoWithAddress} />);
                        setUserInfo(infoWithAddress);
                    } else {
                        const infoWithoutAddress: IMarkerInfo = {
                            ...info!,
                            // locationName: 'Unknown location',
                            address: 'Geocoding failed',
                        };

                        infoWithoutAddress.render = ReactDOMServer.renderToString(<MarkerWindow info={infoWithoutAddress} />);
                        setUserInfo(infoWithoutAddress);
                    }
                }
            );
        }
    };

    const getMarkerColor = (alertStatus: AlertStatus | undefined) => {
        let color = '#2966F4'; // 기본값
        switch (alertStatus) {
            case AlertStatus.EMERGENCY:
                color = '#FF003D';
                break;
            case AlertStatus.BIO:
                color = '#FF7D33';
                break;
            default:
                color = '#2966F4';
                break;
        }
        return color;
    };

    const marker = useMemo(() => {
        if (!data) return undefined;
        return {
            id: Number(params?.id),
            deviceStatus: data?.device?.status,
            location: {
                latitude: data?.location?.latitude,
                longitude: data?.location?.longitude,
                accuracy: data?.location?.accuracy,
                timestamp: data?.location?.timestamp,
            },
            type: MarketTYPE.SINGLE,
            alertStatus: data?.alertStatus as AlertStatus ?? AlertStatus.NONE, // `AlertStatus` Enum으로 변환
            users: [data],
        };
    }, [data]);

    useEffect(() => {
        if (!userInfo) return;

        const contentWithFont = `
            <div style="font-family: 'Noto Sans KR', sans-serif; font-weight: 400;">
                ${userInfo?.render}
            </div>
        `;

        const infoWindowInstance = new google.maps.InfoWindow({
            content: contentWithFont,
        });

        infoWindowInstance.setPosition({ lat: userInfo?.location?.latitude || 0, lng: userInfo?.location?.longitude || 0 });
        infoWindowInstance.open();
    }, [userInfo]);

    const mapOptions = {
        // disableDefaultUI: true,  // 기본 UI 비활성화
        // zoomControl: false,      // 줌 컨트롤 비활성화
        // mapTypeControl: false,   // 지도 유형 선택 비활성화
        // streetViewControl: false, // 스트리트 뷰 컨트롤 비활성화
        fullscreenControl: false // 풀스크린 컨트롤 비활성화
    };

    return (
        <PageConfig showTopNav={true} showSideNav={true}>
            <div className={'w-full h-full relative'}>
                <Suspense fallback={null}>
                    {isMount && data && (
                        <div style={{ width: '100%', height: '100%' }}>
                            <GoogleMap
                                center={{ lat: data?.location?.latitude || 0, lng: data?.location?.longitude || 0 }}
                                zoom={15}
                                mapContainerStyle={{ width: '100%', height: '100%' }}
                                options={mapOptions} // mapOptions 추가
                            >
                                {isMount && data?.location && (
                                    <>
                                        <CircleF
                                            center={{ lat: data?.location?.latitude || 0, lng: data?.location?.longitude || 0 }}
                                            radius={data?.location.accuracy}
                                            options={{
                                                fillColor: '#78bfffcc',
                                                strokeColor: '#78bfffcc',
                                                strokeWeight: 1,
                                            }}
                                        />
                                        <MarkerF
                                            position={{ lat: data?.location?.latitude || 0, lng: data?.location?.longitude || 0 }}
                                            icon={{
                                                url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
                                                    `
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                                        <circle cx="25" cy="25" r="20" fill="${getMarkerColor(data?.alertStatus)}" stroke="white" stroke-width="3"/>
                                                    </svg>
                                                    `
                                                )}`,
                                                scaledSize: new google.maps.Size(40, 40),
                                            }}
                                            onClick={() => openUserInfo(marker)}
                                        />

                                        {isMount && data?.addressLatitude && data?.addressLongitude && (
                                            <MarkerF
                                                position={{ lat: data?.addressLatitude ?? 0, lng: data?.addressLongitude ?? 0 }}
                                                icon={{
                                                    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
                                                        ReactDOMServer.renderToString(<HomeIcon />)
                                                    )}`,
                                                    scaledSize: new google.maps.Size(25, 25),
                                                }}
                                            />
                                        )}
                                    </>
                                )}

                                {userInfo && (
                                    <InfoWindowF
                                        position={{ lat: userInfo?.location?.latitude || 0, lng: userInfo?.location?.longitude || 0 }}
                                        onCloseClick={() => setUserInfo(undefined)}
                                    >
                                        <div
                                            style={{ fontFamily: "'Noto Sans KR', sans-serif", fontWeight: 400 }}
                                            dangerouslySetInnerHTML={{ __html: userInfo?.render }}
                                        />
                                    </InfoWindowF>
                                )}
                            </GoogleMap>
                        </div>
                    )}
                </Suspense>
                <CloseButton className={'absolute top-0 right-0 p-[20px] bg-white m-[20px]'} onClick={() => navigate(-1)} />
            </div>
        </PageConfig>
    );
};

export default UserDetailMapPage;