import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { GoogleMap, MarkerF, CircleF } from '@react-google-maps/api';
import useQueryGetMe from '@/api/auth/getMe.ts';
import useQueryGetUsers, { DeviceStatus } from '@/api/user/getUsers.ts';
import clustering from 'density-clustering';
import ReactDOMServer from 'react-dom/server';
import MarkerWindow from '@/pages/dashboard/MarkerWindow.tsx';
import { IMarker, IMarkerInfo, MarketTYPE } from '@/pages/dashboard/DashboardMapDefault.tsx';

interface IProps {
    className?: string;
}

const CACHE__ZOOM_LEVEL = 'dashboard_map__zoom';
const CACHE__CENTER_X = 'dashboard_map__center__longitude';
const CACHE__CENTER_Y = 'dashboard_map__center__latitude';

const DashboardMapForCareManager = ({ className }: IProps, ref: any) => {
    const [map, setMap] = useState<any>(null);
    const [userInfo, setUserInfo] = useState<IMarkerInfo>();
    const [zoomLevel, setZoomLevel] = useState(0);
    const [infoWindowInstance, setInfoWindowInstance] = useState<google.maps.InfoWindow | null>(null);
    const { data: users } = useQueryGetUsers();
    const { data: me } = useQueryGetMe();

    useImperativeHandle(ref, () => ({
        setLocation(latitude: number, longitude: number, zoom?: number) {
            map?.panTo({ lat: latitude, lng: longitude });
            if (zoom) {
                map?.setZoom(zoom);
            }
        }
    }));

    const openUserInfo = (info?: IMarker) => {
        if (!info || userInfo?.id === info?.id) {
            setUserInfo(undefined);
        } else {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
                { location: { lat: info?.location?.latitude || 0, lng: info?.location?.longitude || 0 } },
                (results, status) => {
                    if (status === 'OK' && results && results[0]) {
                        const infoWithAddress: IMarkerInfo = {
                            ...info!,
                            address: results[0].formatted_address
                        };

                        infoWithAddress.render = ReactDOMServer.renderToString(<MarkerWindow info={infoWithAddress} />);
                        setUserInfo(infoWithAddress);
                    } else {
                        const infoWithoutAddress: IMarkerInfo = {
                            ...info!,
                            address: 'Geocoding failed',
                        };

                        infoWithoutAddress.render = ReactDOMServer.renderToString(<MarkerWindow info={infoWithoutAddress} />);
                        setUserInfo(infoWithoutAddress);
                    }
                }
            );
        }
    };

    useEffect(() => {
        if (!map) {
            return;
        }

        if (infoWindowInstance) {
            infoWindowInstance.close();
        }

        if (userInfo) {
            const infoWindowContent = `
            <div style="font-family: 'Noto Sans KR', sans-serif; font-weight: 400;">
                ${userInfo?.render}
            </div>
        `;

            const newInfoWindowInstance = new google.maps.InfoWindow({
                content: infoWindowContent,
            });
            newInfoWindowInstance.setPosition({ lat: userInfo.location?.latitude || 0, lng: userInfo.location?.longitude || 0 });
            newInfoWindowInstance.open(map);
            setInfoWindowInstance(newInfoWindowInstance);
        }
    }, [userInfo, map]);

    const markers: IMarker[] = useMemo(() => {
        const filtered = users?.filter(item => item?.device?.status) || [];
        const radius = new Map<number, number>();
        radius.set(21, 0.000005);
        radius.set(20, 0.00001);
        radius.set(19, 0.00002);
        radius.set(18, 0.00005);
        radius.set(17, 0.0001);
        radius.set(16, 0.0002);
        radius.set(15, 0.0005);
        radius.set(14, 0.001);
        radius.set(13, 0.002);
        radius.set(12, 0.005);
        radius.set(11, 0.01);
        radius.set(10, 0.02);
        radius.set(9, 0.05);
        radius.set(8, 0.1);
        radius.set(7, 0.2);
        radius.set(6, 0.5);
        radius.set(5, 1);
        radius.set(4, 2);
        radius.set(3, 5);
        radius.set(2, 10);
        radius.set(1, 20);

        const dbscan = new clustering.DBSCAN();
        const clusters = dbscan.run(
            filtered?.map(item => [item?.location?.longitude || 0, item?.location?.latitude || 0]) || [],
            radius.get(zoomLevel) ?? 0.000005,
            2
        );

        const markers: IMarker[] = [];

        clusters?.map(ids => {
            const item = filtered[ids[0]];
            const filteredUsers = ids?.map(id => filtered[id]);

            let deviceStatus;
            if (filteredUsers?.find(item => item?.device?.status === DeviceStatus.EMERGENCY)) {
                deviceStatus = DeviceStatus.EMERGENCY;
            } else if (filteredUsers?.find(item => item?.device?.status === DeviceStatus.ONLINE)) {
                deviceStatus = DeviceStatus.ONLINE;
            } else if (filteredUsers?.find(item => item?.device?.status === DeviceStatus.CHARGING)) {
                deviceStatus = DeviceStatus.CHARGING;
            } else {
                deviceStatus = DeviceStatus.OFFLINE;
            }

            markers?.push({
                id: item?.id,
                deviceStatus: deviceStatus,
                location: {
                    latitude: item?.location?.latitude,
                    longitude: item?.location?.longitude,
                    accuracy: item?.location?.accuracy,
                    timestamp: item?.location?.timestamp
                },
                type: MarketTYPE.CLUSTER,
                users: ids?.map(id => filtered[id])
            });
        });

        dbscan?.noise?.map(id => {
            const item = filtered[id];
            markers?.push({
                id: item?.id,
                deviceStatus: item?.device?.status,
                location: {
                    latitude: item?.location?.latitude,
                    longitude: item?.location?.longitude,
                    accuracy: item?.location?.accuracy,
                    timestamp: item?.location?.timestamp
                },
                type: MarketTYPE.SINGLE,
                users: [item]
            });
        });

        return markers;
    }, [users, zoomLevel]);

    return (
        <div className={`${className}`}>
            <div style={{ width: '100%', height: '100%' }}>
                {me && (
                    <GoogleMap
                        onLoad={(mapInstance) => setMap(mapInstance)}
                        center={{
                            lat: Number(localStorage.getItem(CACHE__CENTER_Y)) || me?.latitude || 37.5759,
                            lng: Number(localStorage.getItem(CACHE__CENTER_X)) || me?.longitude || 126.9768
                        }}
                        zoom={Number(localStorage.getItem(CACHE__ZOOM_LEVEL)) || 18}
                        onZoomChanged={() => {
                            setZoomLevel(map?.getZoom() || 18);
                            localStorage.setItem(CACHE__ZOOM_LEVEL, map?.getZoom()?.toString() || '18');
                        }}
                        onCenterChanged={() => {
                            const center = map?.getCenter();
                            if (center) {
                                localStorage.setItem(CACHE__CENTER_X, center.lng().toString());
                                localStorage.setItem(CACHE__CENTER_Y, center.lat().toString());
                            }
                        }}
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                    >
                        {markers?.map(item => (
                            <MarkerF
                                key={`marker_${item?.id}`}
                                position={{ lat: item?.location?.latitude || 0, lng: item?.location?.longitude || 0 }}
                                icon={{
                                    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
                                        (() => {
                                            let color: string = '#2966F4';
                                            switch (item?.deviceStatus) {
                                                case DeviceStatus.EMERGENCY:
                                                    color = '#FF003D';
                                                    break;
                                                case DeviceStatus.ONLINE:
                                                    color = '#2966F4';
                                                    break;
                                                case DeviceStatus.CHARGING:
                                                    color = '#ADFF45';
                                                    break;
                                                default:
                                                    color = '#BABABA';
                                                    break;
                                            }

                                            return `
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                                    <circle cx="25" cy="25" r="20" fill="${color}" stroke="white" stroke-width="3"/>
                                                    <text x="25" y="30" fill="white" font-size="20" font-family="Arial" text-anchor="middle">
                                                        ${item?.users?.length}
                                                    </text>
                                                </svg>
                                            `;
                                        })() as string
                                    )}`
                                }}
                                onClick={() => openUserInfo(item)}
                            />
                        ))}

                        {userInfo?.type === MarketTYPE.SINGLE && (
                            <CircleF
                                center={{ lat: userInfo?.location?.latitude || 0, lng: userInfo?.location?.longitude || 0 }}
                                radius={userInfo?.location?.accuracy || 0}
                                options={{
                                    fillColor: '#78bfffcc',
                                    strokeColor: '#78bfffcc',
                                    strokeWeight: 1,
                                }}
                            />
                        )}
                    </GoogleMap>
                )}
            </div>
        </div>
    );
};

export default React.forwardRef(DashboardMapForCareManager);
